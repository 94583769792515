import '../css/privacy.css';

import Footer from '../components/footer';
import Terms from '../components/Terms';
export default function Privacy() {
  return (
    <div className="mt-20 overflow-x-hidden">
      <main>
        <h1 className="mb-5 font-sans text-2xl font-semibold">
          Privacy Policy
        </h1>
        <p>
          Discover how Z Platform safeguards your personal information. Our
          Privacy Policy explains the data we collect, how we use it, and our
          commitment to keeping your information secure and private.
        </p>
        <h4>1. ACCEPTANCE OF TERMS</h4>
        <p>
          1. This privacy policy governs the manner in which Nathdwara Advisors
          LLP including its affiliates and subsidiaries (“Company”) collects,
          uses, maintains and discloses information collected from users (each,
          a “User”) of the name neocfo.iowebsite and services offered by the
          Company (“Website”). This privacy policy applies to the Website and
          all services offered by the Company. You agree to and are bound by the
          terms and conditions set forth below and in any modified or additional
          terms that the Company may publish from time to time (collectively,
          the “Privacy Policy”).
        </p>
        <p>
          2. The User does not agree to all of the terms and conditions
          contained in the Terms of Use and this Privacy Policy, do not access
          or use this Website.
        </p>
        <h4>2. PERSONAL INFORMATION</h4>
        <p>
          1. The personal information collected, received, processed, stored,
          dealt or handled by the Company includes, [name, contact details,
          location, unique identifiers, email address].
        </p>
        <p>
          2.The Company may collect personal information from Users in a variety
          of ways, including, but not limited to, when Users visit the Website,
          registers on the Website, seeks information in connection with other
          activities, services, features or resources the Company makes
          available on the Website. Users may be asked for, as appropriate,
          name, email address, mailing address, phone number.
        </p>
        <p>
          3. The Company will collect personal identification information from
          Users only if they voluntarily submit such information to the Company.
          Users can always refuse to supply personal information, except that it
          may prevent them from engaging in certain Website related activities.
        </p>
        <h4>3. NON-PERSONAL INFORMATION</h4>
        <p>
          The Company may collect non-personal information about Users whenever
          they interact with the Website. Non-personal information may include
          the browser name, the type of computer and technical information about
          Users means of connection to the Website, such as the operating system
          and the internet service providers utilized and other similar
          information.
        </p>
        {/* <ul className='list-disc'>
            <li>to identify the User once it registers on the Website;</li>
            <li>to create the User login and administer the User accounts;</li>
            <li>to contact the User and respond to User questions or requests;</li>
            <li>to send the User communication regarding the services and products;</li>
            <li>to provide access to desirable content and products based on the User’s preferences;</li>
            <li>to provide better usability, troubleshooting and site maintenance;</li>
            <li>to understand which parts of the Website are visited and how frequently;</li>
            <li>to screen the orders for potential risks or fraud; and</li>
            <li>to improve and optimize the Website (for example, by generating analytics about how the User’s browse and interact with the Website, and </li>
            <li>to assess the success of the Company’s marketing and advertising campaigns).</li>
           </ul> */}
        <h4> 4. SHARING OF USER’S PERSONAL INFORMATION</h4>
        <p>
          The Company may share the User information with these third parties
          for those limited purposes provided that the User have given the
          Company permission. If the User access those links, the User will
          leave the Website. The Company does not control those sites or their
          privacy practices, which may differ from the Company. The Company does
          not endorse or make any representations about third-party sites.
        </p>
        <p>
          {' '}
          The Company is not responsible for examining or evaluating, and does
          not warrant the offerings of, any businesses or individuals or the
          content of their website. The Company does not assume any
          responsibility or liability for the actions, products, services, and
          content of any other third parties. User should carefully review the
          legal statements and other conditions of use of any website which the
          User accesses through a link from this Website. The User linking to
          any other off-site websites is at the User’s own risk.
        </p>
        <p>
          {' '}
          The Company never collects or maintains information at the Website
          from those the Company knows are under the age of 18 (eighteen) years.
          No part of the Website is structured to attract anyone under the age
          of 18 (eighteen) years.
        </p>
        <p>
          {' '}
          The Company may use third-party service providers to help the Company
          operate its business and the Website or administer activities on the
          Company’s behalf, such as sending out newsletters or surveys.
        </p>
        <h4> 5. IDENTITY THEFT</h4>
        <p>
          There may be instances when the User receive a seemingly legitimate
          looking e-mail asking the User personal information from the User such
          as the User’s credit card details, bank account details, one-time
          passwords, contact information, etc. The Company will never ask for
          such information from you via e-mail.
        </p>
        <br />
        <p>
          Such activities are usually carried on by unauthorized individuals and
          are illegal in nature. They are called phishing or identity theft. In
          case of any suspicion of such activity or on receiving such an e-mail
          the User should be certain it was not sent by the Company. The Company
          advises the User to not respond to such mail and to take whatever
          action you see fit.
        </p>
        <h4> 6. WEB BROWSER COOKIES</h4>
        <p>
          A cookie is an element of data that a website can send to the User’s
          browser, which may then store it on the User’s system. The Company
          uses cookies in some of its pages to store visitors’ preferences and
          record session information. The information that the Company collects
          is then used to ensure a more personalised service level for the
          Users.
        </p>
        <br />
        <p>
          The User can adjust the settings on its browser so that the User will
          be notified when it receives a cookie, or to disable the cookies
          associated with the Website. The User should refer to its browser
          documentation to check if cookies have been enabled on the User’s
          computer or to request not to receive cookies.
        </p>
        <br />
        <p>
          The Website may use “cookies” to enhance User experience. User’s web
          browser places cookies on their hard drive for record-keeping purposes
          and sometimes to track information about them. User may choose to set
          their web browser to refuse cookies or to alert the User when cookies
          are being sent. If they do so, note that some parts of the Website may
          not function properly.
        </p>
        <h4> 7. DATA RETENTION</h4>
        <p>
          The Company will retain the User information only for as long as is
          necessary for the purposes set out in this policy. The Company will
          retain and use the User’s information to the extent necessary to
          comply with the Company’s legal obligations (for example, if the
          Company is required to retain the User’s data to comply with
          applicable laws), resolve disputes, and enforce the Company’s legal
          agreements and policies.
        </p>
        <br />
        <p>
          {' '}
          The Company encourages the User to review the information and inform
          about any discrepancies and information found to be inaccurate shall
          be corrected or amended. Please reach out to the Company
          at pratik@nathdwaraadvisors.comin case the User would like to review
          the information collected by the Company. The User shall at all times,
          take adequate necessary precautions, at the User’s end, to preserve
          the integrity and security of the User’s data which shall include and
          not be limited to the User personal information. All information
          disclosed by the User shall be deemed to be disclosed willingly and
          without any coercion.
        </p>
        <h4>8.LOG DATA</h4>
        <p>
          The Company want to inform the User that whenever you use the Website
          or avail the services, the Company collects data from the User device
          called log data (“Log Data”). This Log Data may include information
          such as the User device’s internet protocol (“IP”) address, device
          name, operating system version, configuration of the app when
          utilising the Website services, the time and date of the User’s use of
          the Website service, and other statistics for the purposes of audit
          trials, trouble shooting, and other purposes.
        </p>
        <h4> 9.INFORMATION SECURITY</h4>
        <p>
          The Company works to protect the User’s information from loss, misuse
          or unauthorized alteration by using industry-recognized security
          safeguards, coupled with carefully developed security procedures and
          practices. The Company maintains electronic and procedural safeguards
          of all information. The Company uses both internal and external
          resources to review the Company’s security procedures. The security
          practices and procedures contains managerial, technical, operational
          and physical security control measures that are commensurate with the
          personal information being collected, received, processed, stored,
          dealt or handled and the nature of the business of the Company.
        </p>
        <h4>10. DISCLAIMER</h4>
        <p>
          This platform and the services are provided on an “as is,” basis. The
          company, its licensors and affiliates make no representations or
          warranties of any kind (express, statutory or implied) as to the
          operation of the platform, provision of services, or the information,
          content, materials, or products included on the platform, or in
          association with the services or any third-party websites or services.
          The company will not be held responsible for any unethical, illegal
          acts performed by the users and the action of each of the users shall
          be their own responsibility solely.
        </p>
        <br />
        <p>
          {' '}
          The Company makes no representation as to providing or storing back-up
          copies of any information submitted to the Company. The User shall be
          solely responsible to ensure that the User maintain back-up copies of
          such information and in the event of any malfunctioning or failing of
          the Website for any reason whatsoever (including on account of
          maintenance), the User may be required to resubmit such information.
        </p>
        <p>
          {' '}
          Although, the Company takes appropriate steps to maintain the security
          of information the Company collects from the User, the Company assumes
          no responsibility of whatsoever nature as to make good the losses and
          damages the User may incur, due to privacy and/or security breach of
          the User information. The Company shall not be liable for any loss or
          damage sustained by reason of disclosure (inadvertent or otherwise) of
          any information and/or omission or inaccuracy with respect to any
          information so disclosed and used whether or not in pursuance of a
          legal process or otherwise.
        </p>
        <h4>11. USE OF THE WEBSITE</h4>
        <p>
          The User understands that, except for information, products or
          services clearly identified as being supplied by the Company, and does
          not operate, control or endorse any information, products or services
          on the internet in any way. Except for the Website, identified
          information, products or services, all information, products and
          services offered through the Website or on the internet generally are
          offered by third parties that are not affiliated with the Company. The
          User also understand that the Company cannot and do not guarantee or
          warrant that files available for downloading through the Website will
          be free of infection or viruses, worms, Trojan horses or other code
          that manifest contaminating or destructive properties. The User are
          responsible for implementing sufficient procedures and checkpoints to
          satisfy the User particular requirements for accuracy of data input
          and output, and for maintaining a means external to the Website for
          the reconstruction of any lost data.
        </p>
        <br />
        <p>
          {' '}
          The User assume total responsibility and risk for the User’s use of
          the Website and the internet. The Company provides the Website and
          related information “as-is” and does not make any express or implied
          warranties, representations or endorsements whatsoever (including
          without limitation warranties of title or non-infringement, or the
          implied warranties of merchantability or fitness for a particular
          purpose) with regard to the service, or on the internet generally, and
          the Company shall not be liable for any cost or damage arising either
          directly or indirectly from any such transaction. It is solely the
          User’s responsibility to evaluate the accuracy, completeness and
          usefulness of all opinions, advice, services, merchandise and other
          information provided through the service or on the internet generally.
          The Company does not warrant that the service will be uninterrupted or
          error-free or that defects in the service will be corrected. You
          understand further that the pure nature of the internet contains
          unedited materials some of which are sexually explicit or may be
          offensive to you. The User access to such materials is at the User
          risk. The Company has no control over and accept no responsibility
          whatsoever for such materials.
        </p>
        <h4> 12.GENERAL</h4>
        <p>
          This privacy policy shall all be governed and construed in accordance
          with the laws of India applicable to agreements made and to be
          performed in India. You agree that any legal action or proceeding
          between the User and the Company for any purpose concerning this
          policy or the parties’ obligations hereunder shall be brought
          exclusively in a court of competent jurisdiction in Bengaluru. The
          Company’s failure to insist upon or enforce strict performance of any
          provision of this policy shall not be construed as a waiver of any
          provision or right. Neither the course of conduct between the parties
          nor trade practice shall act to modify any provision of this policy.
          The Company may assign its rights and duties under this policy to any
          party at any time without notice to the User. The User may choose to
          withdraw its consent provided hereunder at any point in time. Such
          withdrawal of consent must be sent in writing
          to pratik@nathdwaraadvisors.com.  In case you later withdraw the User
          consent, the Company requests the User not to access the Website and
          also reserve the right to not provide you any services through the
          Website.
        </p>
        <br />
        <p>
          {' '}
          The Company provides links to other websites and third-party platforms
          (“Third-Party Platforms”). Such Third-Party Platforms may collect
          information about you. The Company is not responsible for the privacy
          practices or the content of those linked websites and Third-Party
          Platforms. The Company recommends the User to review the privacy
          policies of such Third-Party Platforms.
        </p>
        <p>
          {' '}
          The Website has the discretion to update this privacy policy at any
          time. The Company encourages Users to frequently check this page for
          any changes to stay informed about how the Company is helping to
          protect the personal information it collects. The User acknowledge
          and agree that it is the responsibility of the User to review this
          privacy policy periodically and become aware of modifications.
        </p>{' '}
        <br />
        <p>
          {' '}
          If Users have any questions or comments about the policy outlined
          above, the User can contact the Company’s Grievance Redressal Officer
          at the below mentioned details:
        </p>
        <br />
        <h5>
          <b>Contact Person Name :</b> Mr. Pratik Shah{' '}
        </h5>
        <h5>
          <b>Address:</b> Adarsh Palm Retreat, Bellandur, Bengaluru - 560 103{' '}
        </h5>
        <h5>
          {' '}
          <b>Email Address :</b> pratik@neocfo.io
        </h5>
        <br />
        <br />
      </main>
      <div className="flex justify-center">
        <Terms />
      </div>
      <Footer />
    </div>
  );
}
