import React from 'react';
import Carousel from 'react-multi-carousel';

import useIsMobile from '../hooks/isMobile';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 5,
    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1024 },
    items: 3,
    partialVisibilityGutter: 24,
  },
  tablet: {
    breakpoint: { max: 1024, min: 512 },
    items: 2,
    partialVisibilityGutter: 24,
  },
  mobile: {
    breakpoint: { max: 512, min: 0 },
    items: 1,
  },
};

const CarouselContent = ({ children }) => {
  const screenSize = useIsMobile();
  return (
    <Carousel
      responsive={responsive}
      showDots={screenSize === 'mobile'}
      draggable
      swipeable
      focusOnSelect
      pauseOnHover
      infinite
      autoPlay
      autoPlaySpeed={3000}
      keyBoardControl
      customTransition="all .5s ease-in-out"
      transitionDuration={500}
      arrows={false}
    >
      {children}
    </Carousel>
  );
};

CarouselContent.propTypes = {
  children: React.Children,
};

export default CarouselContent;
