import ScheduleBtn from './ScheduleBtn';

const Terms = () => {
  return (
    <div className="mx-auto my-40 mt-60 flex w-[90%] flex-col items-center gap-5 overflow-hidden rounded-2xl bg-hero-texture bg-cover bg-no-repeat px-1 py-12 md:items-start md:px-20">
      <h1 className="max-w-[28ch] text-center text-xl text-primary md:text-start md:text-2xl">
        Transform Your Financial Processes with Neo CFO
      </h1>
      <ScheduleBtn />
    </div>
  );
};

export default Terms;
