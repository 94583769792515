import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';
import { useEffect, useRef } from 'react';

import { cn } from '../utils/utils';

gsap.registerPlugin(ScrollTrigger);

const FadeInWrapper = ({
  children,
  className,
  id,
  duration = 1.5,
  delay = 0,
  direction = 'bottom', // "left", "right", "bottom", or "top"
}) => {
  const fadeRef = useRef(null);

  useEffect(() => {
    let x = 0;
    let y = 0;

    if (direction === 'right') {
      x = 100; // Start 100px to the right
    } else if (direction === 'left') {
      x = -100; // Start 100px to the left
    } else if (direction === 'top') {
      y = -100; // Start 100px above
    } else if (direction === 'bottom') {
      y = 100; // Start 100px below
    }

    // Apply the GSAP animation
    gsap.from(fadeRef.current, {
      opacity: 0,
      x: x,
      y: y,
      duration: duration,
      delay: delay,
      ease: 'power3.out',
      scrollTrigger: {
        trigger: fadeRef.current,
        start: 'top 80%', // Animation starts when the element is 80% in the viewport
      },
    });
  }, [duration, delay, direction]);

  return (
    <div ref={fadeRef} id={id} className={cn(className)}>
      {children}
    </div>
  );
};

FadeInWrapper.propTypes = {
  children: React.Children,
  className: React.string,
  id: React.string,
  duration: React.number,
  delay: React.number,
  direction: React.string,
};

export default FadeInWrapper;
