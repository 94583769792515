export const faqData = [
  {
    question: 'What is NEO CFO?',
    answer:
      'NEO CFO is a comprehensive finance automation platform designed to streamline and automate your financial processes. From managing automation of accounting entries and GST taxation to handling procure-to-pay processes, NEO CFO helps you optimize and simplify your day-to-day financial tasks, freeing up to 80% of your time.',
  },
  {
    question: 'Is my financial information secure?',
    answer:
      'Yes, at NEO CFO, we prioritize your data security. We use advanced encryption methods and security protocols to ensure your financial data is protected and confidential and comply with industry-standard security practices to ensure your financial information is protected. Regular security audits and updates are conducted to maintain the highest level of security.',
  },
  {
    question: 'What services does NEO CFO offer?',
    answer:
      'NEO CFO offers a wide range of services, including, but not limited to: Automated accounting entries, GST taxation management, Procure-to-pay process automation, Financial data integration, Predictive analytics for P&L and cash flow outcomes, Expense categorization and management, Reconciliation of GST input with accounting records.',
  },
  {
    question: 'Can I integrate other financial tools with the platform?',
    answer:
      'Yes, NEO CFO supports integration with various financial tools and platforms. You can easily connect your accounting software (i.e., Tally or Zoho Books), GST portal, and other financial services/bank accounts to streamline your workflows and ensure all your data is centralized in one place.',
  },
  {
    question: 'How does NEO CFO handle GST compliance?',
    answer:
      'NEO CFO automates GST compliance by integrating with the GST portal and providing tools for managing GST returns, reconciling GST input with accounting records, and ensuring accurate GST calculations. This helps you stay compliant with the latest GST regulations effortlessly.',
  },
  {
    question: 'Can multiple users access the same NEO CFO account?',
    answer:
      'Yes, NEO CFO allows multiple users to access the same account. You can add team members and assign different roles and permissions based on their responsibilities. This facilitates collaboration and ensures that everyone on your team can contribute effectively.',
  },
];
