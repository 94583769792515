import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';

import useResponsive from '../hooks/isMobile';

gsap.registerPlugin(ScrollTrigger);

const SynchronizedScrollComponent = () => {
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const nextSectionRef = useRef(null);
  const screenSize = useResponsive();
  useEffect(() => {
    const leftSection = leftRef.current;
    const rightSection = rightRef.current;
    const nextSection = nextSectionRef.current;

    const scrollTrigger = ScrollTrigger.create({
      trigger: rightSection,
      start: 'top top', // Start when the top of the right section hits the top of the viewport
      scrub: true, // Use scrubbing to tie the animation to scroll position
      pin: false,
      // markers:true,

      onLeave: () => {
        // Optionally scroll to the next section if needed
        if (
          leftSection.scrollTop >=
          leftSection.scrollHeight - leftSection.clientHeight - 10
        ) {
          nextSection.scrollIntoView({ behavior: 'smooth' });
        }
      },
      onEnterBack: () => {
        // Refresh ScrollTrigger when re-entering the section
        scrollTrigger.refresh();
      },
    });

    // Handle scrolling of left section to sync with right section
    const handleLeftScroll = () => {
      const leftScrollPercentage =
        leftSection.scrollTop /
        (leftSection.scrollHeight - leftSection.clientHeight);
      rightSection.scrollTop =
        leftScrollPercentage *
        (rightSection.scrollHeight - rightSection.clientHeight);
    };

    // Handle scrolling of right section to sync with left section
    const handleRightScroll = () => {
      const rightScrollPercentage =
        rightSection.scrollTop /
        (rightSection.scrollHeight - rightSection.clientHeight);
      leftSection.scrollTop =
        rightScrollPercentage *
        (leftSection.scrollHeight - leftSection.clientHeight);
    };

    leftSection.addEventListener('scroll', handleLeftScroll);
    rightSection.addEventListener('scroll', handleRightScroll);

    return () => {
      scrollTrigger.kill();
      leftSection.removeEventListener('scroll', handleLeftScroll);
      rightSection.removeEventListener('scroll', handleRightScroll);
    };
  }, []);

  return (
    <>
      <style>
        {`
          .scrollbar-hide::-webkit-scrollbar {
            display: none;
          }

          .scrollbar-hide {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
        `}
      </style>

      <div className="mt-12 grid h-[400px] w-full grid-cols-2 gap-2 overflow-hidden">
        {/* Left Section */}
        <div
          ref={leftRef}
          className="scrollbar-hide relative h-full w-full overflow-hidden overflow-y-scroll"
        >
          <div className="flex h-[400px] items-start justify-start">
            <img
              src="cfo1.webp"
              alt=""
              className="h-[400px] w-full rounded-2xl p-2"
            />
          </div>
          <div className="flex h-[400px] items-start justify-start">
            <img
              src="cfo2.webp"
              alt=""
              className="h-[400px] w-full rounded-2xl p-2"
            />
          </div>
        </div>

        {/* Right Section */}
        <div
          ref={rightRef}
          className="scrollbar-hide relative w-full overflow-y-scroll"
        >
          <div className="flex h-[400px] items-end justify-start">
            <h1
              className="mb-2 text-[16px] text-[#829392]"
              style={{ lineHeight: '25px' }}
            >
              <p
                className="pb-2 text-[18px] text-[#3B3B3B]"
                style={{ lineHeight: '35px' }}
              >
                What is Neo CFO?
              </p>
              Neo CFO is an advanced financial automation platform designed to
              streamline and simplify finance management for businesses of all
              sizes. From automating accounting entries, GST taxation, and
              procure-to-pay processes, to integrating seamlessly with major
              accounting platforms and government portals.
            </h1>
          </div>
          <div className="flex h-[500px] items-end justify-start">
            <h1
              className="mb-2 text-[16px] text-[#829392]"
              style={{ lineHeight: '25px' }}
            >
              <p
                className="text-[18px] text-[#3B3B3B]"
                style={{ lineHeight: '35px' }}
              >
                Our Mission
              </p>
              Our mission is to shift the ratio from spending 80% of time on
              data preparation to allocating 80% of time to meaningful data
              insights and financial analysis.
            </h1>
          </div>
        </div>
      </div>

      {screenSize === 'mobile' && <p>Lorem ipsum dolor sit amet.</p>}
    </>
  );
};

export default SynchronizedScrollComponent;
