import '../css/terms.css';

import Footer from '../components/footer';
import Term from '../components/Terms';
export default function Terms() {
  return (
    <div className="mt-20 overflow-x-hidden">
      <main>
        <h1 className="mb-5 font-sans text-2xl font-semibold">
          Terms & conditions
        </h1>
        <p>
          Review the guidelines for using Z Platform. Our Terms & Conditions
          outline your rights, responsibilities, and the legal framework of our
          services. By using our platform, you agree to these terms, ensuring a
          secure and transparent experience.
        </p>

        <h4>1. ACCEPTANCE OF TERMS</h4>

        <p>
          1. Welcome to neocfo.io(“Website”) and our product software including
          any browser extensions, mobile applications and any other downloadable
          application that we provide as part of our product (“NeoCFO”)
          including all of the Content (defined below).NeoCFO can be deployed
          on either of the following deployment models: (a) hosted with NeoCFO,
          (b) hosted on User’s cloud server, (c) hosted on User’s physical
          server, and (d) hosted on third party service providers’ servers. The
          Website, NeoCFO, Content and any service provided at the Website shall
          collectively be referred to as “Services”.
        </p>

        <p>
          2.The Website is operated and owned by Nathdwara Advisors LLP (“We”/
          “Us” / “Our”), a Limited Liability Partnership, incorporated under the
          Limited Liability Partnership Act, 2008 and having its registered
          office at Adarsh Palm Retreat, Bellandur, Bengaluru – 560103.
        </p>

        <p>
          3. These terms and conditions (“Terms of Use”) are an agreement
          between Us and You (“User”/ “You”/ “Your”). This Agreement sets forth
          the general terms and conditions of Your use of the Service and any of
          its products or services available on the Website. Your access to the
          Services and use of the Services is conditional on your acceptance of
          and compliance with these Terms of Use as well as the Privacy Policy
          (defined below). We and the User are each a “Party” and collectively
          the “Parties”.
        </p>

        <p>
          4. These Terms of Use shall apply to all visitors, Users and others
          who access the Services. By accessing or using the Services You agree
          to be bound by these Terms of Use. These Terms of Use shall be read
          along with the terms and conditions under our Privacy Policy (please
          refer to the Privacy Policy section for more information).
        </p>

        <p>
          5. We reserve the right to change these Terms of Use from time to time
          without prior notice. You acknowledge and agree that it is Your
          responsibility to review these Terms of Use periodically to
          familiarize yourself with any modifications. Your continued use of
          this Services after such modifications will constitute acknowledgement
          and agreement of the modified terms and conditions.
        </p>
        <p>
          6. Use of Services is available only to persons who can form a legally
          binding contract under the laws of India or any other country as
          applicable. If You are a minor i.e., You may use the Services only
          with the involvement of a parent or guardian.
        </p>

        <h4>2. RESPONSIBLE USE AND CONDUCT</h4>
        <p>
          1. In order to access the Services, You may be required to provide
          certain information about yourself (such as identification, email,
          phone number, contact details, etc.) as part of the registration
          process, or as part of Your ability to use the Services. You agree
          that any information You provide will always be accurate, correct, and
          up to date.
        </p>

        <p>
          2. You are responsible for maintaining the confidentiality of any
          information. Accordingly, You are responsible for all activities that
          occur under Your account(s).
        </p>
        <p>
          3. Accessing (or attempting to access) any of Our Services by any
          means other than through the means We provide, is strictly prohibited.
          You specifically agree not to access (or attempt to access) any of the
          Services through any automated, unethical, or unconventional means.
        </p>
        <p>
          4. Engaging in any activity that disrupts or interferes with the
          Services, including the servers and/or networks to which the Services
          are located or connected, is strictly prohibited.
        </p>

        <p>
          5. Attempting to copy, duplicate, reproduce, sell, trade, or resell
          Our resources are strictly prohibited.
        </p>
        <p>
          6. You are solely responsible for any consequences, losses, or damages
          that We may directly or indirectly incur or suffer due to any
          unauthorized activities conducted by You, as explained above, and may
          incur criminal or civil liability.
        </p>
        <p>
          7. We have the right at Our sole discretion to remove any content
          that, We feel in Our judgment does not comply with this Terms of Use,
          along with any content that We feel is otherwise offensive, harmful,
          objectionable, inaccurate, or violates any third-party copyrights or
          trademarks. We are not responsible for any delay or failure in
          removing such content. If You post content that We choose to remove,
          You hereby consent to such removal, and consent to waive any claim
          against Us.
        </p>

        <p>
          8. We do not assume any liability for any content posted by You or any
          other third-party users of the Services. However, any content posted
          by You using any open communication tools on our Website, provided
          that it does not violate or infringe on any third-party copyrights or
          trademarks, becomes Our property and as such, gives us a perpetual,
          irrevocable, worldwide, royalty-free, exclusive license to reproduce,
          modify, adapt, translate, publish, publicly display and/or distribute
          as we see fit. This only refers and applies to content posted via open
          communication tools as described and does not refer to information
          that is provided as part of the registration process, necessary in
          order to use the Services. All information provided as part of our
          registration process is covered by our Privacy Policy.
        </p>

        <p>
          9. You agree to indemnify and hold Us harmless and Our parent company
          and affiliates, and their directors, officers, managers, employees,
          donors, agents, and licensors, from and against all losses, expenses,
          damages and costs, including reasonable attorneys’ fees, resulting
          from any violation of these Terms of Use or the failure to fulfil any
          obligations relating to your account incurred by you or any other
          person using your account. We reserve the right to take over the
          exclusive defence of any claim for which we are entitled to
          indemnification under these Terms of Use. In such event, You shall
          provide Us with such cooperation as is reasonably requested by Us.
        </p>

        <p>
          10.Although the Services may link to other websites, we are not,
          directly or indirectly, implying any approval, association,
          sponsorship, endorsement, or affiliation with any linked website,
          unless specifically stated herein. We are not responsible for
          examining or evaluating, and does not warrant the offerings of, any
          businesses or individuals or the content of their websites. We do not
          assume any responsibility or liability for the actions, products,
          services, and content of any other third parties. You should carefully
          review the legal statements and other conditions of use of any website
          which You access through a link from the Services. Your linking to any
          other off-site websites is at Your own risk.
        </p>

        <h4>3. PRIVACY</h4>
        <p>
          1. Your privacy is very important to Us, which is why We have created
          a separate Privacy Policy in order to explain in detail how we
          collect, manage, process, secure, and store your private information.
          Our privacy policy is included under the scope of these Terms of Use.
          To read our Privacy Policy in its entirety, please see the below
          (“Privacy Policy”):
        </p>

        <h4> 4. USER’S ACCOUNT</h4>
        <p>
          1.In consideration of Your use of the Services, you agree to: (a)
          maintain and promptly update any information You provide Us, to keep
          it accurate, current and complete; (b) maintain confidentiality and
          security of your password and identification; (c) notify Us
          immediately of any unauthorized use of your account or other breach of
          security; (d) accept all responsibility for any and all activities
          that occur under your account; and (e) accept all risks of
          unauthorized access to the data and any other information you provide
          Us.
        </p>
        <p>
          2. User shall be responsible for all usage of the User’s account and
          password, whether or not authorized by the User. Although We will not
          be liable for Your losses caused by any unauthorized use of Your
          account, You may be liable for Our losses or such other parties, as
          the case may be, due to any unauthorized use of Your account.
        </p>
        <p>
          3. Inactive User Accounts. We reserve the right to terminate unpaid
          User accounts that are inactive for a continuous period of 60 (sixty)
          days. In the event of such termination, all data associated with such
          User account will be deleted. We will provide You prior notice of such
          termination and option to back-up your data. The data deletion policy
          may be implemented with respect to any or all of the Services. Each of
          the Services will be considered an independent and separate service
          for the purpose of calculating the period of inactivity. In other
          words, activity in one of the Services is not sufficient to keep the
          User account in another Services active. In case of accounts with more
          than 1 (one) User, if at least 1 (one) of the User is active, the
          account will not be considered inactive.
        </p>

        <h4>5. SUBSCRIPTION TO BETA SERVICE</h4>
        <p>
          1. We may offer certain Services as closed or open beta services
          (“Beta Service” or “Beta Services”) for the purpose of testing and
          evaluation. You agree that We have the sole authority and discretion
          to determine the period of time for testing and evaluation of Beta
          Services. We will be the sole judge of the success of such testing and
          the decision, if any, to offer the Beta Services as commercial
          services. You will be under no obligation to acquire a subscription to
          use any paid Services as a result of your subscription to any Beta
          Service. We reserve the right to fully or partially discontinue, at
          any time and from time to time, temporarily or permanently, any of the
          Beta Services with or without notice to You. You agree that We will
          not be liable to You or to any third party for any harm related to,
          arising out of, or caused by the modification, suspension or
          discontinuance of any of the Beta Services for any reason.
        </p>

        <h4>6.PAYMENTS</h4>
        <p>
          1.The Services are available under one time purchase or subscription
          plans of various durations. Payments for subscription plans can be
          made by various payment options available at the time of the
          subscription purchase. Your subscription will be automatically renewed
          at the end of each subscription period unless You downgrade Your paid
          subscription plan to a free plan or inform us that You do not wish to
          renew the subscription. At the time of automatic renewal, the
          subscription fee will be charged through the last payment method used
          by You. We provide you the option of changing the details if You would
          like the payment for the renewal to be made through a different
          payment option. If You do not wish to renew the subscription, You must
          inform us at least 30 days prior to the renewal date unless expressly
          stated otherwise in purchase agreement or order. If you have not
          downgraded to a free plan and if You have not informed us that you do
          not wish to renew the subscription, You will be presumed to have
          authorized Us to charge the subscription fee to the payment option
          last used by You.
        </p>

        <p>
          2. From time to time, we may change the price of any Service or charge
          for use of Services that are currently available free of charge. Any
          increase in charges will not apply until the expiry of Your then
          current billing cycle. You will not be charged for using any Service
          unless You have made the purchase of a service or opted for a paid
          subscription plan.
        </p>
        <p>
          3. Cancellations:Your subscription can be cancelled at any time from
          the account page on the Website or by
          emailing to pratik@nathdwaraadvisors.com. Once your cancellation is
          confirmed, Your automatic subscription will be stopped, and You will
          not be billed in the future. You will retain access to your account
          and all its data until the end of the subscription period that You
          have paid for, after which date Your account will be inaccessible. You
          will not be able to guarantee access to your data after that date, so
          please ensure that any migration, download or backup of your data is
          completed ideally prior to initiating Your cancellation, or at least,
          prior to the last date of Your subscription period.
        </p>
        <h4>7. REFUNDS</h4>
        <p>
          1. If you make a cancellation request within 48 hours of your payment
          method being charged, and You explicitly request a refund by
          emailing pratik@nathdwaraadvisors.com within that same time period, We
          will refund the last payment made by You. The refund will be processed
          via the same payment method that was used to make Your subscription
          payment. However, please note that in such cases, We will not be able
          to guarantee that You will have access to any data that may be stored
          under Your account. Please ensure that any migration, download or
          backup of Your data is completed prior to initiating your refund
          request. Please note that in order to successfully process Your
          refund, You must:
          <p>
            a. confirm a cancellation from your accounts page on the Website;
            and
          </p>
          <p>
            b. send an email to pratik@nathdwaraadvisors.comwith a refund
            request within 48 (forty eight) hours of your last payment to Us,
            failing the above We will be unable to process any refunds.
          </p>
        </p>
        <h4>8. USERS’ CODE OF CONDUCT</h4>
        <p>
          1.You may use the Services for Your personal and/or business use or
          for internal business purpose in the organization that You represent.
          You may connect to the Services using any internet browser supported
          by the Services or downloading the mobile application. You are
          responsible for obtaining access to the internet and the equipment
          necessary to use the Services. Any use of the Services or any of its
          functionality for a purpose not permitted by these Terms of Use can be
          grounds for immediate revocation of any usernames, pass codes or other
          permissions that may have been granted by Us for use of the Services.
        </p>
        <p>
          2. You are specifically prohibited from: (i) downloading, copying, or
          retransmitting any or all of the Services content without, or in
          violation of, a written license or agreement with Us or a specific
          option to do so as provided by Us; (ii) using any data mining or
          similar data gathering or extraction methods; (iii) manipulating or
          otherwise displaying the Services content by using, framing or similar
          navigational technology; and (iv) using the Services other than for
          its intended purpose. Such unauthorized use may also violate
          applicable laws including without limitation copyright and trademark
          laws, the laws of privacy and publicity, and applicable communications
          regulations and statutes.
        </p>
        <h4>9. LIMITATION OF LIABILITY</h4>
        <p>
          1.You expressly understand and agree that any claim against Us shall
          be limited to 25% (twenty-five percent) of the amount You paid, if
          any, for Use of products and/or Services. We will not be liable for
          any direct, indirect, incidental, consequential or exemplary loss or
          damages which may be incurred by You as a result of using the
          Services, or as a result of any changes, data loss or corruption,
          cancellation, loss of access, or downtime to the full extent that
          applicable limitation of liability laws apply.
        </p>

        <h4>10. COPYRIGHTS / TRADEMARKS</h4>
        <p>
          1. All content and materials available on neocfo.io, including but not
          limited to text, graphics, website name, code, images and logos are
          Our intellectual property and are protected by applicable copyright
          and trademark law. Any inappropriate use, including but not limited to
          the reproduction, distribution, display or transmission of any content
          on the Services is strictly prohibited unless specifically authorized
          by Us.
        </p>
        <p>
          2. The Services are operated by Us. All the content published,
          featured or displayed on the Services, including, but not limited to,
          materials, documents, text, data, images (photographic and moving),
          graphics, illustrations, videos, Software, selection and arrangement
          thereof, and other information and content included in or available at
          the Website collectively referred to as the “Content”, is owned by Us.
          Any Content will be published, featured or displayed on the Services
          with prior agreement with Us.
        </p>
        <p>
          3. You may not use meta-tags or any other “hidden text” utilizing
          content or any other name, trademark or Our product name without Our
          prior written permission.
        </p>
        <p>
          4. In addition, the look and feel of the Services, including all Our
          page headers, custom graphics, button icons and scripts, trademark
          and/or trade dress and may not be copied, imitated or used, in whole
          or in part, without Our prior written permission. All other
          trademarks, registered trademarks, product names and company names or
          logos mentioned on the Services are the property of their respective
          owners. Reference to any products, services, processes or other
          information, by trade name, trademark, manufacturer, supplier or
          otherwise does not constitute or imply endorsement, sponsorship or
          recommendation thereof by Us.
        </p>
        <p>
          5. This Terms of Use does not transfer to you any intellectual
          property owned by Us or third parties, and all rights, titles, and
          interests in and to such property will remain (as between the parties)
          solely with Us. All trademarks, graphics and logos used in connection
          with our Services, are Our trademarks or Our registered trademarks or
          Our licensors. Other trademarks, graphics and logos used in connection
          with the Services may be the trademarks of other third parties. Your
          use of the Services grants You no right or license to reproduce or
          otherwise use any of Our or third-party trademarks.
        </p>

        <h4>11.TERMINATION OF USE</h4>
        <p>
          1.You agree that the We may, at its sole discretion, suspend or
          terminate Your access to all or part of our Services with or without
          notice and for any reason, including, without limitation, breach of
          this Terms of Use. Any suspected illegal, fraudulent or abusive
          activity may be grounds for terminating Your relationship and may be
          referred to appropriate law enforcement authorities. Upon suspension
          or termination, your right to use the Services provided by us will
          immediately cease, and we reserve the right to remove or delete any
          information that you may have on file with Us, including any account
          or login information.
        </p>
        <h4>12. DISCLAIMERS</h4>
        <p>
          1.YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT AS OTHERWISE
          EXPRESSLY PROVIDED IN THESE TERMS OF USE, WE, OUR STRATEGIC PARTNERS,
          AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND OTHER
          REPRESENTATIVES, EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE
          MAKE NO WARRANTY THAT: (I) THE SERVICES WILL MEET YOUR REQUIREMENTS;
          (II) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
          ERROR-FREE; (III) INFORMATION THAT MAY BE OBTAINED VIA THE SERVICES
          WILL BE ACCURATE OR RELIABLE; (IV) THE QUALITY OF ANY AND ALL
          SERVICES, INFORMATION OR OTHER MATERIAL, INCLUDING ALL MERCHANDISE,
          GOODS AND SERVICES, OBTAINED OR PURCHASED BY YOU DIRECTLY OR
          INDIRECTLY WILL MEET YOUR EXPECTATIONS OR NEEDS; AND (V) ANY ERRORS IN
          THE SERVICES WILL BE CORRECTED.
        </p>
        <p>
          2. You must bear the risks associated with the use of the Services. We
          will not be responsible for any errors or omissions or for the results
          obtained from the use of such information or for any technical
          problems you may experience with the Services.
        </p>
        <p>
          3. This disclaimer constitutes an essential part of these Terms of
          Use. To the fullest extent permitted under applicable law, we shall
          not be liable for any indirect, incidental, special, consequential or
          exemplary damages, including but not limited to, damages for loss of
          profits, goodwill, use, data or other intangible losses arising out of
          or in connection with the Services, its services or these Terms of
          Use.
        </p>
        <h4>13. COMMUNICATIONS FROM US</h4>
        <p>
          By registering for the Services, You give Us consent for using Your
          email address to send You service announcements, administrative
          messages and newsletters. You understand that these communications
          shall be considered part of using the Services.
        </p>
        <h5>GENERAL</h5>
        <p>
          1. Governing Law, Jurisdiction: The Services are controlled by Us from
          our offices located in India. It can be accessed by most countries
          around the world. As each country has laws that may differ from those
          of India, by accessing our Services, you agree that the statutes and
          laws of India without regard to the conflict of laws will apply to all
          matters relating to the use of the Services. Any issues arising out of
          use of Services and any conflict with regard to these Terms of Use and
          Privacy Policy shall be governed and construed in accordance with the
          laws of India and subject to Section 2 below, the courts of Bangalore,
          India shall have the exclusive jurisdiction. You hereby agree to
          personal jurisdiction by such courts and waive any jurisdictional,
          venue, or inconvenient forum objections to such courts. Our failure to
          enforce any right or provision of these Terms of Use will not be
          considered a waiver of those rights.
        </p>
        <p>
          2. Dispute Resolution: If any dispute or difference of any kind
          whatsoever shall arise between the Parties in connection with or
          arising out of these Terms of Use and Privacy Policy (whether before
          or after the termination) the concerned representatives of the Parties
          shall promptly and in good faith negotiate with a view to an amicable
          resolution and settlement of the dispute. In the event, no amicable
          resolution or settlement is reached within a period of 30 (thirty)
          days, such dispute or difference shall be referred to a sole
          arbitrator mutually appointed by the Parties or, upon the failure of
          the Parties to agree upon a sole arbitrator, within a period of 10
          (ten) days, each Party shall appoint 1 (one) arbitrator each and the 2
          (two) appointed arbitrators shall appoint the third arbitrator who
          shall act as the presiding arbitrator. Arbitration shall be conducted
          in accordance with the provisions of the Indian Arbitration and
          Conciliation Act, 1996 and any amendments thereof. The arbitration
          proceedings shall be held at Bangalore, India. The existence of any
          dispute or difference or the initiation or continuance of the
          arbitration proceedings shall not postpone or delay the performance by
          the Parties of their respective obligations pursuant to these Terms of
          Use. It is agreed that the arbitrators shall also determine and make
          an award as to the costs of the arbitration proceedings.
          Notwithstanding anything contained herein, the Parties shall have a
          right to institute legal proceedings to prevent any continuing breach
          of the provisions of these Terms of Use to seek an injunctive or any
          other specific relief.
        </p>
        <p>
          3. Notice: We may provide any notice to the User under these Terms of
          Use by sending a message to the email address then associated with
          User account. The notices provided by Us by email will be effective
          when the email is sent. It is the User’s responsibility to keep the
          User’s email address current. The User will be deemed to have received
          any email sent to the email address then associated with User account
          when We send the email, whether or not the User actually receive the
          email. To give Us notice under these Terms of Use, the User must
          contact Us by personal delivery, overnight courier or registered or
          certified mail to the mailing address listed below: Notices provided
          by personal delivery will be effective immediately. Notices provided
          by overnight courier will be effective 1 (one) business day after they
          are sent. Notices provided registered or certified mail will be
          effective 3 (three) business days after they are sent. Address: Adarsh
          Palm Retreat, Bellandur, Bengaluru – 560103.Attention: Mr. Pratik
          ShahE-mail: pratik@nathdwaraadvisors.com
        </p>
        <p>
          4. Force Majeure: Except for payment obligations for services already
          rendered, non-performance by either Party of these Terms of Use shall
          be excused to the extent that performance is rendered impossible by a
          strike, acts of God, governmental acts or restrictions, failure of
          suppliers, acts of war or terrorism, or any other reason where failure
          to perform is beyond the reasonable control of the nonperforming
          Party. The time for performance of this Terms of Use shall be extended
          for a period equal to the duration of such events.
        </p>
        <p>
          5. Severability: If any provision of the Terms of Use is invalid under
          any applicable statute or rule of law, it is to that extent to be
          deemed omitted and all other terms and provisions of the Terms of Use
          shall nevertheless remain in full force and effect so long as the
          economic or legal substance of the transactions contemplated hereby is
          not affected in any manner materially adverse to any Party. Upon such
          determination that any term or other provision is invalid, illegal or
          incapable of being enforced, Us and the User shall negotiate in good
          faith to modify the Terms of Use so as to effectuate the original
          intent as closely as possible in an acceptable manner in order that
          the transactions contemplated hereby are consummated as originally
          contemplated to the greatest extent possible.
        </p>
        <p>
          6. Relationship of Parties: We and the User are not employees, agents,
          partners or joint venturers of each other. The User shall not have the
          right to enter into any agreement on Our behalf.
        </p>
        <p>
          7. Entire Terms: The entire understanding between Us and the User
          hereto relating to the subject matter hereof is contained herein and
          We make no warranties, representations or undertakings hereto except
          as expressly provided herein.
        </p>
        <p>
          8. Assignment: Notwithstanding anything contained herein, You agree
          and covenant that We may, at Our sole discretion, be entitled to
          assign its rights and obligations under this Terms of Use to any of
          its affiliates or to any entity, and in such case, such affiliate or
          entity shall be entitled to enforce these Terms of Use.
        </p>
        <p>
          9. Maintenance of Records: You shall maintain the records
          independently of the Website (by way physical copies etcetera) and We
          reserve the right to seek copies of such records for their own use
          including record keeping. You acknowledge and agree that We shall have
          the right to retain Your information for as long as it deems fit and
          to be in compliance with applicable provisions of the law.
        </p>
        <p>
          10. Contact Information: If You have any questions or comments about
          our Terms of Use as outlined above, you can contact us at:
        </p>
        <br />
        <h5>
          <b>Contact Person Name :</b> Mr. Pratik Shah{' '}
        </h5>
        <h5>
          {' '}
          <b>Email Address :</b> pratik@neocfo.io
        </h5>
        <br />
        <br />
      </main>
      <div className="flex justify-center">
        <Term />
      </div>
      <Footer />
    </div>
  );
}
