import '../css/style.css';

import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import useResponsive from '../hooks/isMobile';

export default function Footer() {
  const nav = useNavigate();
  const screenSize = useResponsive();

  return (
    <>
      {screenSize === 'desktop' && (
        <footer
          id="footer"
          className="bg-gray-900 pb-2 pt-6 text-white"
          style={{ fontWeight: '0' }}
        >
          <div id="footer-container visible" className="mx-auto w-full px-4">
            {/* Upper Footer */}
            <div id="footer-up" className="">
              {/* Left Section */}
              <div id="footer-up-left" className=" ">
                <img src="./logo_white.webp" alt="logo" className="w-20" />
                <p className="text-[16px] text-gray-300">
                  <i
                    style={{
                      fontFamily: 'Playfair Display, serif',
                      fontWeight: '0',
                      fontSize: '16px',
                      lineHeight: '1',
                    }}
                  >
                    Empowering
                  </i>{' '}
                  India&apos;s Businesses,
                  <br /> One Click at a Time.
                </p>
                <a
                  href="mailto:support@neocfo.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 flex h-7 cursor-pointer items-center justify-center gap-2 rounded-md bg-white bg-opacity-10 p-2 text-[16px] text-white backdrop-blur-md transition-colors duration-200 hover:bg-opacity-30"
                  style={{ fontWeight: '0' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M20.5 3h-17C2.675 3 2 3.675 2 4.5v15c0 .825.675 1.5 1.5 1.5h17c.825 0 1.5-.675 1.5-1.5v-15c0-.825-.675-1.5-1.5-1.5zm0 2L12 11.25 3.5 5h17zM3.5 19v-11L12 13.75 20.5 8v11h-17z" />
                  </svg>
                  support@neocfo.io
                </a>
              </div>
              {/* Right Section */}
              <div id="footer-up-right" className="">
                <div>
                  <p className="mt-1 text-[16px] font-semibold text-gray-400">
                    Products
                  </p>
                  <li
                    className="mt-1 cursor-pointer list-none text-[14px] text-white"
                    onClick={() => nav('/feature')}
                  >
                    Features
                  </li>
                </div>
                <div className="mt-1 flex flex-col items-start">
                  <p className="text-[16px] font-semibold text-gray-400">
                    Company
                  </p>
                  <li
                    className="mt-1 cursor-pointer list-none text-[14px] text-white"
                    onClick={() => nav('/story')}
                  >
                    Our Story
                  </li>
                  <li
                    className="mt-2 cursor-pointer list-none text-[14px] text-white"
                    onClick={() => nav('/blogpage')}
                  >
                    Blogs
                  </li>
                  <li
                    className="mt-2 cursor-pointer list-none text-[14px] text-white"
                    onClick={() => nav('/schedule')}
                  >
                    Contact
                  </li>
                </div>
              </div>
            </div>
            {/* Social Media Links */}
            <div id="footer-social-media" className="mt-6 flex flex-col">
              <div className="mt-1 flex gap-4 text-base text-white">
                <FaLinkedin size={24} />
                <FaSquareXTwitter size={24} />
                <FaInstagram size={24} />
              </div>
            </div>
            {/* Lower Footer */}
            <div
              id="footer-down"
              className="ml-[3.3rem] mr-[3.3rem] mt-6 flex flex-col items-start justify-between border-t border-gray-500 pt-2 text-gray-400 sm:flex-row"
            >
              <p className="text-[13px]">All Rights Reserved</p>
              <div className="mt-2 flex gap-8 sm:mt-0">
                <p
                  className="cursor-pointer text-[13px]"
                  style={{ fontWeight: '0' }}
                  onClick={() => nav('/privacy')}
                >
                  Privacy Policy
                </p>
                <p
                  className="cursor-pointer text-[13px]"
                  style={{ fontWeight: '0' }}
                  onClick={() => nav('/terms')}
                >
                  Terms & Conditions
                </p>
              </div>
            </div>
          </div>
        </footer>
      )}

      {screenSize === 'tablet' && (
        <footer
          id="footer"
          className="bg-gray-900 pb-2 pt-6 text-white"
          style={{ fontWeight: '0' }}
        >
          <div id="footer-container visible" className="mx-auto w-full px-4">
            {/* Upper Footer */}
            <div id="footer-up" className="">
              {/* Left Section */}
              <div id="footer-up-left" className=" ">
                <img src="./logo_white.webp" alt="logo" className="w-20" />
                <p className="text-[16px] text-gray-300">
                  <i
                    style={{
                      fontFamily: 'Playfair Display, serif',
                      fontWeight: '0',
                      fontSize: '16px',
                      lineHeight: '1',
                    }}
                  >
                    Empowering
                  </i>{' '}
                  India&apos;s Businesses,
                  <br /> One Click at a Time.
                </p>
                <a
                  href="mailto:support@neocfo.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 flex h-10 cursor-pointer items-center justify-center gap-2 rounded-md bg-white bg-opacity-10 p-2 text-[16px] font-semibold text-white backdrop-blur-md transition-colors duration-200 hover:bg-opacity-30"
                  style={{ fontWeight: '0' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M20.5 3h-17C2.675 3 2 3.675 2 4.5v15c0 .825.675 1.5 1.5 1.5h17c.825 0 1.5-.675 1.5-1.5v-15c0-.825-.675-1.5-1.5-1.5zm0 2L12 11.25 3.5 5h17zM3.5 19v-11L12 13.75 20.5 8v11h-17z" />
                  </svg>
                  support@neocfo.io
                </a>
              </div>
              {/* Right Section */}
              <div id="footer-up-right" className="">
                <div>
                  <p className="text-[13px] font-semibold text-gray-400">
                    Products
                  </p>
                  <li
                    className="mt-1 cursor-pointer list-none text-[13px] text-white"
                    onClick={() => nav('/feature')}
                  >
                    Features
                  </li>
                </div>
                <div className="flex flex-col items-start">
                  <p className="list-none text-[13px] font-semibold text-gray-400">
                    Company
                  </p>
                  <li
                    className="mt-1 cursor-pointer list-none text-[13px] text-white"
                    onClick={() => nav('/story')}
                  >
                    Our Story
                  </li>
                  <li
                    className="mt-2 cursor-pointer list-none text-[13px] text-white"
                    onClick={() => nav('/blogpage')}
                  >
                    Blogs
                  </li>
                  <li
                    className="mt-2 cursor-pointer list-none text-[13px] text-white"
                    onClick={() => nav('/schedule')}
                  >
                    Contact
                  </li>
                </div>
              </div>
            </div>
            {/* Social Media Links */}
            <div id="footer-social-media" className="mt-6 flex flex-col">
              <div className="mt-1 flex gap-4 text-base text-white">
                <FaLinkedin size={24} />
                <FaSquareXTwitter size={24} />
                <FaInstagram size={24} />
              </div>
            </div>
            {/* Lower Footer */}
            <div
              id="footer-down"
              className="ml-[3.3rem] mr-[3.3rem] mt-6 flex flex-col items-start justify-between border-t border-gray-500 pt-2 text-gray-400 sm:flex-row"
            >
              <p className="text-[13px]">All Rights Reserved</p>
              <div className="mt-2 flex gap-8 sm:mt-0">
                <p
                  className="cursor-pointer text-[13px]"
                  style={{ fontWeight: '0' }}
                  onClick={() => nav('/privacy')}
                >
                  Privacy Policy
                </p>
                <p
                  className="cursor-pointer text-[13px]"
                  style={{ fontWeight: '0' }}
                  onClick={() => nav('/terms')}
                >
                  Terms & Conditions
                </p>
              </div>
            </div>
          </div>
        </footer>
      )}

      {screenSize === 'mobile' && (
        <footer
          id="footer"
          className="bg-gray-900 pb-2 pl-[1.2rem] pt-6 text-white"
          style={{ fontWeight: '0' }}
        >
          <div id="footer-container visible" className="mx-auto w-full px-4">
            {/* Upper Footer */}
            <div id="footer-up" className="">
              {/* Left Section */}
              <div id="" className=" ">
                <img src="./logo_white.webp" alt="logo" className="w-20" />
                <p className="text-[12px] text-gray-300">
                  <i
                    style={{
                      fontFamily: 'Playfair Display, serif',
                      fontWeight: '0',
                      fontSize: '12px',
                      lineHeight: '1',
                    }}
                  >
                    Empowering
                  </i>{' '}
                  India&apos;s Businesses,
                  <br /> One Click at a Time.
                </p>
                <a
                  href="mailto:support@neocfo.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 flex h-[40px] w-[161px] cursor-pointer items-center justify-center gap-2 rounded-[6px] bg-white bg-opacity-5 p-2 text-[12px] text-white backdrop-blur-md transition-colors duration-200 hover:bg-opacity-30"
                  style={{ fontWeight: '0' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M20.5 3h-17C2.675 3 2 3.675 2 4.5v15c0 .825.675 1.5 1.5 1.5h17c.825 0 1.5-.675 1.5-1.5v-15c0-.825-.675-1.5-1.5-1.5zm0 2L12 11.25 3.5 5h17zM3.5 19v-11L12 13.75 20.5 8v11h-17z" />
                  </svg>
                  support@neocfo.io
                </a>
              </div>
              {/* Right Section */}
              <div
                id="footer-up-right"
                className=""
                style={{
                  lineHeight: '28px',
                }}
              >
                <div>
                  <p className="text-[13px] font-semibold text-gray-400">
                    Products
                  </p>
                  <p
                    className="text- mt-1 cursor-pointer list-none text-[13px] text-white"
                    onClick={() => nav('/feature')}
                  >
                    Features
                  </p>
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-[13px] font-semibold text-gray-400">
                    Company
                  </p>
                  <li
                    className="mt-1 cursor-pointer list-none text-[13px] text-white"
                    onClick={() => nav('/story')}
                  >
                    Our Story
                  </li>
                  <li
                    className="mt-2 cursor-pointer list-none text-[13px] text-white"
                    onClick={() => nav('/blogpage')}
                  >
                    Blogs
                  </li>
                  <li
                    className="mt-2 cursor-pointer list-none text-[13px] text-white"
                    onClick={() => nav('/schedule')}
                  >
                    Contact
                  </li>
                </div>
              </div>
            </div>
            {/* Social Media Links */}
            <div id="footer-social-media" className="mt-6 flex flex-col">
              <div className="mt-1 flex gap-4 text-base text-white">
                <FaLinkedin size={24} />
                <FaSquareXTwitter size={24} />
                <FaInstagram size={24} />
              </div>
            </div>
            {/* Lower Footer */}
            <div
              id=""
              className="mt-6 flex flex-col items-start justify-between border-t border-gray-500 pt-2 text-[11px] text-gray-400"
            >
              <p className="text-[13px]">All Rights Reserved</p>
              <div className="mt-2 flex flex-col gap-3 sm:mt-0">
                <p
                  className="cursor-pointer text-[13px]"
                  style={{ fontWeight: '0' }}
                  onClick={() => nav('/privacy')}
                >
                  Privacy Policy
                </p>
                <p
                  className="cursor-pointer text-[13px]"
                  style={{ fontWeight: '0' }}
                  onClick={() => nav('/terms')}
                >
                  Terms & Conditions
                </p>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}
