import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaInstagram } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GoArrowUpRight } from 'react-icons/go';
import { IoLogoLinkedin } from 'react-icons/io';
import { Link } from 'react-router-dom';

import Logo from './Logo';
import ScheduleBtn from './ScheduleBtn';
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="fixed left-1/2 top-0 z-50 flex w-full max-w-screen-3xl -translate-x-1/2 items-center justify-between bg-primary/5 p-2 shadow-sm backdrop-blur">
      <Logo />
      {isMenuOpen}
      <div className="hidden flex-1 items-center justify-center gap-16 md:flex">
        <Link
          className="cursor-pointer leading-4 text-green-800 transition hover:text-green-950"
          to="/story"
        >
          Our Story
        </Link>
        <Link
          className="cursor-pointer text-green-800 hover:text-green-950"
          to="/feature"
        >
          Features
        </Link>
        {/* <Link
          className="cursor-pointer text-green-800 hover:text-green-950"
          to="/blogpage"
        >
          Blogs
        </Link> */}
      </div>

      {/* Schedule a Demo for large screens */}
      <div className="hidden md:block">
        <ScheduleBtn />
      </div>

      {/* Hamburger Menu Icon for small screens */}
      <div className="flex items-center text-[#144944] md:hidden">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? (
            <AiOutlineClose className="text-green-900" size={24} />
          ) : (
            <GiHamburgerMenu size={24} />
          )}
        </button>
      </div>

      {/* Dropdown Menu for small screens */}
      {isMenuOpen && (
        <div
          style={{
            background: '',
            backdropFilter: 'blur(55px)',
          }}
          className={`absolute left-0 top-[3.7rem] z-50 flex h-[100vh] w-full flex-col justify-between bg-[#d7cccc41] bg-opacity-90 text-white shadow-xl sm:hidden ${isMenuOpen ? 'animate-uparniche' : 'animate-nicheupar'}`}
        >
          <div className="ml-7 text-green-800">
            <Link
              to="/story"
              className="mt-5 flex w-full items-center p-4 text-center font-sans"
            >
              Our Story
            </Link>
            <Link
              to="/feature"
              className="flex w-full p-4 text-center font-sans"
            >
              Features
            </Link>
            {/* <Link
              to="/blogpage"
              className="flex w-full p-4 text-center font-sans"
            >
              Blogs
            </Link> */}
          </div>

          <div className="ml-5 mr-5 flex flex-col gap-5 border-b border-gray-400 p-5 text-[14px]">
            <p className="w-[200px] rounded-lg text-[14px] font-medium text-green-900">
              Transform Your Financial Processes with Neo CFO
            </p>
            <Link
              className="items-left flex w-[8rem] -translate-y-4 rounded-sm border bg-[#144944] px-4 py-2 text-white"
              style={{
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
              }}
              to="/schedule"
            >
              SCHEDULE DEMO <GoArrowUpRight className="text-xs text-white" />
            </Link>
          </div>

          <div className="mb-10 ml-10 mr-10 mt-[-6rem] flex gap-3">
            <IoLogoLinkedin className="cursor-pointer text-xl text-green-800" />
            <FaInstagram className="cursor-pointer text-xl text-green-800" />
            <FaSquareXTwitter className="cursor-pointer text-xl text-green-800" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
