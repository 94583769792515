import Carousel from 'react-multi-carousel';

import useIsMobile from '../hooks/isMobile';
import FadeInWrapper from './FadeInWrapper';

const featureResponsive = {
  desktop: {
    breakpoint: { max: 1920, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 512 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 512, min: 0 },
    items: 1,
  },
};

const storiesData = [
  { id: 1, image: './storycard1.webp', alt: 'Person 1' },
  { id: 2, image: './storycard2.webp', alt: 'Person 2' },
  { id: 3, image: './storycard3.webp', alt: 'Person 3' },
  { id: 4, image: './storycard4.webp', alt: 'Person 4' },
];

const StoryCarousel = () => {
  const screenSize = useIsMobile();
  return (
    <FadeInWrapper direction="bottom" className="mb-[12rem] mt-[14rem] w-[90%]">
      <div className="ml-1 p-5">
        <span className="text-[36px]" style={{ fontWeight: '400' }}>
          <i
            className="font-serif text-[46px] italic"
            style={{ fontWeight: '400' }}
          >
            Smart Finance,
          </i>{' '}
          Secure Data
        </span>
      </div>
      <Carousel
        responsive={featureResponsive}
        arrows={false}
        showDots={screenSize === 'mobile'}
        autoPlay
        infinite
        pauseOnHover
      >
        {storiesData.map((card) => (
          <img
            src={card.image}
            key={card.id}
            alt={card.alt}
            className="size-[300px]"
          />
        ))}
      </Carousel>
    </FadeInWrapper>
  );
};

export default StoryCarousel;
