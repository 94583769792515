import 'react-multi-carousel/lib/styles.css';

import { Route, Routes, useLocation } from 'react-router-dom';

import Navbar from './components/header';
// import Blogpage from './pages/blogpage';
import Feature from './pages/feature';
import Home from './pages/home';
import Privacy from './pages/privacy';
import Read from './pages/readblog';
import Schedule from './pages/schedule';
import Story from './pages/story';
import Terms from './pages/terms';

function App() {
  const location = useLocation();

  // Specify routes where you want the Header to appear
  const routesWithHeader = [
    '/schedule',
    '/story',
    '/feature',
    '/blogpage',
    '/privacy',
    '/read',
    '/terms',
  ];

  return (
    <div className="mx-auto w-full max-w-screen-3xl">
      {routesWithHeader.includes(location.pathname) && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/story" element={<Story />} />
        <Route path="/feature" element={<Feature />} />
        {/* <Route path="/blogpage" element={<Blogpage />} /> */}
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/read" element={<Read />} />
      </Routes>
    </div>
  );
}

export default App;
