import '../css/read.css';

import { useRef, useState } from 'react';
import {CiSaveUp2 } from 'react-icons/ci';
import { GoArrowUpRight } from 'react-icons/go';
import { LuCopy } from 'react-icons/lu';

import Footer from '../components/footer';

const storiesData = [
  {
    id: 1,
    button: 'Finance',
    image: './dylan-calluy-JpflvzEl5cg-unsplash.jpg',
    text: 'Top 5 Ways to Revolutionize Your Accounting Processes',
    alt: 'Person 1',
  },
  {
    id: 2,
    image: './austin-distel-744oGeqpxPQ-unsplash.jpg',
    text: 'The Ultimate Guide toMaximizing Efficiency',
    alt: 'Person 2',
    button: 'banking',
  },
  {
    id: 3,
    button: 'Accounting',
    image: './exploreMore2.jpeg',
    text: 'The Future of Financial Automation',
    alt: 'Person 3',
  },
  {
    id: 4,
    button: 'Finance',
    image: './ExploreMore3.jpeg',
    text: 'The Future of Financial Automation',
    alt: 'Person 4',
  },
];

const StoryCarousel = () => {
  const [stories, setStories] = useState(storiesData);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const carouselRef = useRef(null);

  // Smooth transition duration
  const transitionDuration = 300; // in milliseconds

  // Scroll the carousel to the right
  const scrollRight = () => {
    if (isTransitioning) return; // Prevent multiple clicks during transition

    setIsTransitioning(true);
    carouselRef.current.style.transition = `transform ${transitionDuration}ms ease-in-out`;
    carouselRef.current.style.transform = `translateX(-300px)`; // Move one card width (300px)

    // After the transition ends, rearrange the order of stories
    setTimeout(() => {
      const firstStory = stories[0];
      const newStories = stories.slice(1).concat(firstStory);
      setStories(newStories);
      carouselRef.current.style.transition = 'none'; // Remove transition temporarily to reset position
      carouselRef.current.style.transform = 'translateX(0)'; // Reset position to 0
      setIsTransitioning(false);
    }, transitionDuration);
  };


  return (
    <div className="relative mt-8 w-full overflow-hidden rounded-lg p-5">
      <div className="flex justify-between">
        <h2 className="section-header text-[#144944]">Explore More</h2>
        <div className="view-more-btn">
          <button
            onClick={scrollRight}
            className="rounded-full bg-[#1449441A] px-2 py-1 text-xs text-[#144944]"
          >
            View More <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      {/* Left Arrow Button */}

      {/* Story Track Container */}
      <div
        className="mt-10 flex w-full flex-col gap-10 rounded-lg sm:gap-5 md:flex-row"
        ref={carouselRef}
        style={{
          transition: `transform ${transitionDuration}ms ease-in-out`,
          overflow: 'hidden',
        }}
      >
        {/* Story Cards */}
        {stories.map((story) => (
          <div
            key={story.id}
            className="relative ml-3 h-[280px] w-[90%] rounded-xl bg-white bg-opacity-80 text-center sm:ml-0 sm:h-[250px]"
          >
            {/* the text */}
            <div className="absolute inset-0 bg-black bg-opacity-0"></div>
            <div className="absolute left-2 top-2 w-[90%] text-white md:flex md:justify-between">
              <div className="z-10 hidden rounded-full bg-[#FFFFFF33] px-2 py-1 text-xs sm:text-xs md:block lg:text-xs">
                {story.button}
              </div>
              {/* <div className="justify-center flex   sm:hidden rounded-full w-6 h-6  bg-[#FFFFFF33] z-10 items-center">
            <GoArrowUpRight/>
        </div> */}
            </div>
            <div className="absolute bottom-2 left-4 right-4 z-10">
              <h2
                style={{ color: '#FFFFFF' }}
                className="text-md mb-2 text-left font-semibold sm:text-lg"
              >
                {story.text}
              </h2>
              <p
                className="lg:text-md mb-1 text-left text-xs sm:text-sm"
                style={{ color: '#FFFFFF99' }}
              >
                5 min read
              </p>
            </div>
            <img
              src={story.image}
              alt={story.alt}
              className="h-full w-full rounded-xl sm:h-[250px]"
            />
          </div>
        ))}
      </div>

      {/* Right Arrow Button */}
    </div>
  );
};
export default function Read() {
  return (
    <div className="overflow-x-hidden">
      <div className="ml-4 flex w-[10rem] translate-y-20 flex-col items-start justify-center gap-4 sm:ml-0 sm:w-full sm:flex-row sm:items-center sm:justify-start sm:gap-64">
        <i className="fas fa-arrow-left rounded-lg border p-2 text-[#829392] sm:translate-x-16"></i>
        <div className="blog-tag rounded-full text-xs text-[#144944]">
          Accounting
        </div>
      </div>
      <main className="blog-container mt-20">
        <article className="blog-content">
          <h1 className="font-sans text-3xl tracking-wider text-[#3B3B3B]">
            The Ultimate Guide to <br /> Maximizing Efficiency
          </h1>
          <p className="blog-intro mt-5 max-w-sm text-xs text-[#7A7A7A]">
            Every finance leader knows the challenges of dealing with economic
            uncertainty, making accurate forecasts, and cutting costs wisely.
            And that’s where automation steps in to lend a hand.
          </p>

          <hr />
          <br />
          <div className="author-info ml-2 text-xs text-[#7A7A7A]">
            Pratik Shah • 10 min read
          </div>
          <div className="image-container">
            <img
              alt="A hand holding a dollar bill with a blurred background of trees"
              className="h-[24rem] rounded-2xl"
              src="./ExploreMore3.jpeg"
            />
          </div>
          <div className="text-content">
            <h2 className="text-md ml-2 font-semibold text-[#3B3B3B]">
              This process involves registering new vendors and getting them
              approved for business engagement.{' '}
            </h2>
            <p className="mt-5 text-[#7A7A7A]">
              Sticking to manual financial processes is like steering a sinking
              ship in a tech-driven world. Finance automation isn’t just a
              fleeting trend. It’s the next big thing in finance. It promises to
              improve efficiency, reduce risk, increase accuracy, and support
              strategic decision-making. Every finance leader knows the
              challenges of dealing with economic uncertainty, making accurate
              forecasts, and cutting costs wisely. And that’s where automation
              steps in to lend a hand. For finance leaders, like CFOs,
              automation is a secret weapon. It helps ramp up performance
              efficiencies. It gives a big boost to cost efficiency. And helps
              get the maximum value from assets. But don’t just take our word
              for it! In a 2022 Gartner survey on Finance Technologies, 88%
              reported benefits from Robotic Process Automation (RPA). To name a
              few, improved efficiency, increased speed and agility, and a big
              boost to employee productivity. Now, that’s worth considering,
              right?
            </p>
            <p className="text-[#7A7A7A]">
              The finance department has many workflows and processes. These
              involve different departments, people, systems, and data.
              Performing these tasks manually not only reduces the efficiency of
              the entire system but also significantly impacts performance.
              Finance automation is leveraging modern technologies like
              Artificial Intelligence (AI), Machine Learning (ML), and Robotic
              Process Automation (RPA) to streamline the process. Automating
              finance processes makes the operation smoother. Reduces potential
              human errors. And saves valuable time for strategic planning.
              Finance process automation is a key component in finance
              transformation. It provides real-time data intelligence and
              supports decision-making.
            </p>
          </div>
          <div className="text-content">
            <h3 className="text-md ml-2 mt-5 font-semibold">
              Tapping AI to maintain the competitive edge A few working examples
              will help put this emphasis on automation into perspective. <br />
              Lorem ipsum dolor sit amet{' '}
            </h3>
            <p className="mt-5 text-[#7A7A7A]">
              Automation provides clear benefits for finance leaders. It
              strengthens their capacity to drive strategic initiatives, cut
              costs, and produce precise financial reports. Automating finance
              processes allows finance leaders to move their attention from
              regular, lengthy tasks to strategic tasks that add value. This
              results in better productivity, cost-effectiveness, accuracy, and
              compliance. It also offers immediate financial insights, efficient
              risk management, and scalable solutions. Here are some of the main
              benefits that finance automation can provide to leaders:
            </p>
            <div className="quote-content">
              &quot;Automating finance processes allows finance leaders to move their
              attention from regular, lengthy tasks to strategic tasks that add
              value.&quot;
            </div>
            <p className="text-[#7A7A7A]">
              Check out our end of season subscription discounts with a
              Moneycontrol pro subscription absolutely free. Use code EOSO2021.
              Click here for details.
            </p>
          </div>
          <div className="text-content">
            <h3 className="text-md ml-2 mt-5 font-semibold">
              Tapping AI to maintain the competitive edge A few working examples
              will help put this emphasis on automation into perspective.{' '}
            </h3>
            <p className="mt-5 text-[#7A7A7A]">
              Take advantage of our AI-powered financial automation bundle. It
              saves you time in deployment and is cost-effective. Find out more
              about our bundle here: Finance Automation Solutions- Buy Bundle;
              Save More (rpatech.ai) If you’re unsure about how AI and RPA can
              benefit your business, schedule a free half-day discovery call
              with our experts. During this workshop, they’ll help you: Identify
              the most suitable processes in your business for automation Assess
              the feasibility and complexity of automation Understand how to
              embark on your automation journey with confidence and ease
            </p>
          </div>

          <div className="blog-footer ml-2 mt-7 flex items-center gap-8 text-[#829392]">
            <span className="flex items-center">
              3<img src="./heart_833472.webp" alt='img' className="h-4 w-3" />
            </span>
            <LuCopy className="text-[#829392]" />
            <CiSaveUp2 className="text-[#6d7575]" />
          </div>
        </article>
      </main>

      <section className="card-container mt-20">
        <StoryCarousel />
      </section>

      <div className="flex justify-center">
        <div id="demo" style={{ marginTop: '150px', height: '270px' }}>
          <h1 className="font-sans text-[#144944]">
            Transform Your Financial <br />
            Processes with Neo CFO
          </h1>
          <div className="w-full bg-red-500">
            <button
              className="flex -translate-y-4 items-center rounded-sm bg-white px-4 py-2 font-sans font-semibold text-[#144944]"
              style={{ fontSize: '12px' }}
            >
              SCHEDULE DEMO{' '}
              <GoArrowUpRight className="text-xs text-[#144944]" />
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
