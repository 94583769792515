import '../css/schedule1.css';

import { InlineWidget } from 'react-calendly';

import FadeInWrapper from '../components/FadeInWrapper';
import Footer from '../components/footer';

const correctText = [
  { icon: '/correct.webp', text: 'AI Powered Invoice Processing' },
  { icon: '/correct.webp', text: 'Real-Time Financial Reporting' },
  { icon: '/correct.webp', text: 'Seamless Integration With Tally/Zoho Books' },
  { icon: '/correct.webp', text: 'Customisable Approval Workflows' },
  { icon: '/correct.webp', text: 'Vendor Management & KYC' },
];

export default function Schedule() {
  return (
    <div className="overflow-x-hidden">
      <div className="mx-auto grid w-[90%] grid-cols-1 items-start gap-4 pb-10 pt-32 md:grid-cols-[2fr_3fr]">
        <FadeInWrapper direction="left" className="pt-20">
          <h1 className="mb-5 text-xl md:max-w-[24ch] md:text-2xl lg:text-3xl">
            Discover How Neo CFO Can <br />
            <i className="font-serif text-[1.2em]">Revolutionize</i> Your
            Business
          </h1>
          <p className="text-xs md:max-w-[50ch] md:text-sm">
            Schedule a personalized demo today, and see how we can tailor our
            solutions to meet your unique business needs.
          </p>
          <div className="mt-5">
            {correctText.map((feature, index) => (
              <div key={index} className="mb-4 flex items-center">
                <img src={feature.icon} alt="icon" className="mr-3 h-10 w-10" />
                <span className='text-sm'>{feature.text}</span>
              </div>
            ))}
          </div>
        </FadeInWrapper>

        <div className="pt-10 md:pt-0">
          <InlineWidget
            url="https://calendly.com/pratik-neocfo/30min"
            iframeTitle="Schedule a demo for Neo CFO platform"
            dataResize={true}
            pageSettings={{
              hideGdprBanner: true,
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: '#0b3d33',
              textColor: '#0b3d33',
            }}
            styles={{
              width: '100%',
              height: 780,
            }}
          />
        </div>
      </div>

      <div className="mx-auto w-[90%] text-center">
        <FadeInWrapper direction="left" className="mb-8 text-xl md:text-3xl">
          What You&apos;ll Gain from <br /> Your
          <i className="font-serif text-[1.2em]">Personalized Demo</i>
        </FadeInWrapper>
        <FadeInWrapper direction="right" className="mb-20">
          <div className="flex flex-wrap items-center justify-center gap-5 md:gap-8">
            <img
              src="benifit1.webp"
              alt="Benefit 1"
              className="size-[340px] md:size-[380px]"
            />
            <img
              src="benifit2.webp"
              alt="Benefit 2"
              className="size-[340px] md:size-[380px]"
            />
            <img
              src="benifit3.webp"
              alt="Benefit 3"
              className="size-[340px] md:size-[380px]"
            />
          </div>
        </FadeInWrapper>
      </div>
      <Footer />
    </div>
  );
}
