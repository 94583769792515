export const testimonials = [
  {
    name: 'Kevin S',
    title: 'Founder of a Startup',
    text: 'Neo CFO has completely transformed how I manage finances, allowing me to focus more on growing my business!',
    image:
      'https://icons.veryicon.com/png/o/miscellaneous/bitisland-world/person-18.png',
  },
  {
    name: 'Rebecca T',
    title: 'Head of Finance',
    text: 'Automating repetitive tasks with Neo CFO has freed up my time for more strategic work—game-changing!',
    image:
      'https://icons.veryicon.com/png/o/miscellaneous/bitisland-world/person-18.png',
  },
  {
    name: 'Rahul',
    title: 'Small Business Owner',
    text: 'The GST integration is seamless. I no longer worry about tax filing errors—it’s all handled for me!',
    image:
      'https://icons.veryicon.com/png/o/miscellaneous/bitisland-world/person-18.png',
  },
  {
    name: 'Priyanka',
    title: 'Founder',
    text: 'Neo CFO has made financial reporting incredibly simple, saving hours every month on closing the books!',
    image:
      'https://icons.veryicon.com/png/o/miscellaneous/bitisland-world/person-18.png',
  },
  {
    name: 'Nithin',
    title: 'Finance Manager',
    text: 'The integration with our accounting software was flawless. Neo CFO truly makes multi-org management easy!',
    image:
      'https://icons.veryicon.com/png/o/miscellaneous/bitisland-world/person-18.png',
  },
  {
    name: 'Kishor S',
    title: 'SME Business Owner',
    text: 'Neo CFO’s AI-driven categorization of expenses has eliminated the guesswork—what a relief!',
    image:
      'https://icons.veryicon.com/png/o/miscellaneous/bitisland-world/person-18.png',
  },
];

export const featureCardContents = [
  { id: 1, image: './storycard1.webp', alt: 'Story card 1' },
  { id: 2, image: './storycard2.webp', alt: 'Story card 2' },
  { id: 3, image: './storycard3.webp', alt: 'Story card 3' },
  { id: 4, image: './storycard4.webp', alt: 'Story card 4' },
];

export const homeCard = [
  { id: 1, image: './image1.webp', alt: 'card 1' },
  { id: 2, image: './image2.webp', alt: 'card 2' },
  { id: 3, image: './image3.webp', alt: 'card 3' },
  { id: 4, image: './image4.webp', alt: 'card 4' },
  { id: 5, image: './image5.webp', alt: 'card 5' },
];

export const featuresCard = [
  {
    title: 'Automated Accounting Entries',
    text: 'Say goodbye to manual data entry. Neo CFO automates your accounting processes, from capturing transactions to reconciling accounts, ensuring accuracy and saving time.',
    image: '/G1.png',
  },
  {
    title: 'GST Compliance & Taxation Management',
    text: 'Simplify your tax filing with automated GST calculations, return filings, and reconciliation of GST input with accounting records, ensuring compliance without the hassle.',
    image: '/G2.png',
  },
  {
    title: 'Procure-to-Pay Automation',
    text: 'Streamline your procurement process from purchase orders to vendor payments with automated workflows, reducing errors and improving efficiency across your organization.',
    image: '/G3.png',
  },
  {
    title: 'Seamless System Integrations',
    text: 'Neo CFO integrates effortlessly with your existing accounting platforms and GST portals, providing a unified experience without the need for complex configurations.',
    image: '/G4.png',
  },
  {
    title: 'AI-Powered Financial Insights',
    text: 'Unlock the power of AI with predictive analytics that provide valuable insights into your profit and loss, cash flow, and financial trends, empowering you to make informed decisions.',
    image: '/G5.png',
  },
];
