import React from 'react';
import { GoArrowUpRight } from 'react-icons/go';
import { Link } from 'react-router-dom';

const ScheduleBtn = () => {
  return (
    <Link
      className="flex max-w-fit cursor-pointer items-center rounded bg-white px-4 py-2 text-sm text-primary shadow"
      to="/schedule"
    >
      Schedule Demo <GoArrowUpRight className="text-primary" />
    </Link>
  );
};

export default ScheduleBtn;
