import { useLayoutEffect, useState } from 'react';

const useResponsive = () => {
  const [screenSize, setScreenSize] = useState(getScreenSize());

  const handleResize = () => {
    setScreenSize(getScreenSize());
  };

  function getScreenSize() {
    const width = window.innerWidth;
    if (width >= 1200) return 'desktop';
    if (width >= 700) return 'tablet';
    return 'mobile';
  }

  useLayoutEffect(() => {
    handleResize(); // Set initial size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return screenSize;
};

export default useResponsive;
