import '../css/style.css';

import { useState } from 'react';
import { FaMinus } from 'react-icons/fa';
import { GrAdd } from 'react-icons/gr';

import FadeInWrapper from '../components/FadeInWrapper';
import Footer from '../components/footer';
import StoryCarousel from '../components/storycorousel';
import Terms from '../components/Terms';
import { faqData } from './contents/story-content';

export default function Story() {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <>
      <div className="relative flex flex-col items-center justify-center overflow-x-hidden sm:justify-center">
        <FadeInWrapper
          direction="left"
          id="first"
          className="mt-20 flex w-full flex-col items-start gap-3 px-8 text-center sm:ml-10 sm:items-start sm:px-0 sm:text-left md:ml-20 md:mt-32 lg:ml-[22%] lg:mt-44"
        >
          <span className="text-base sm:text-sm">Our Story</span>
          <h1
            className="text-start text-2xl text-slate-700 sm:text-3xl"
            style={{ fontWeight: '500' }}
          >
            <i
              className="text-4xl font-normal"
              style={{
                fontFamily: 'Playfair Display, serif',
                fontStyle: 'italic',
              }}
            >
              Reimagine
            </i>{' '}
            Financial Management
          </h1>
          <p className="w-full text-start text-sm text-slate-500 sm:max-w-[400px] md:max-w-[450px] lg:max-w-[730px]">
            Discover how your finance teams can access up-to-date information
            and generate accurate reports and responses grounded in company
            knowledge.
          </p>
        </FadeInWrapper>

        <FadeInWrapper
          direction="right"
          className="mt-16 flex flex-col items-center gap-4 sm:gap-8 lg:flex-row lg:items-end lg:justify-center lg:gap-8"
        >
          {/* Experience Box */}
          <div className="flex h-[150px] w-[90%] flex-col justify-end rounded-lg bg-[#144944] p-3 text-[#829392] sm:w-[400px] lg:h-[180px] lg:w-[195px]">
            <span className="w-full p-[4px] text-xl text-white">+15</span>
            <p className="text-start text-[14px] lg:text-left">
              years of combined experience in finance and technology
            </p>
          </div>

          {/* AI Processing Box */}
          <div
            className="t flex h-[150px] w-[90%] flex-col items-center justify-end rounded-lg p-3 text-xs text-[#ffffff9d] sm:w-[400px] lg:h-[180px] lg:w-[195px]"
            style={{ backgroundColor: '#77C043' }}
          >
            <span className="w-full p-[4px] text-[25px] text-xl text-white">
              15x
            </span>
            <p className="text-start text-[14px] lg:text-left">
              faster transaction processing with our AI algorithms
            </p>
          </div>

          {/* Time Reduction Box */}
          <div
            className="flex h-[150px] w-[90%] flex-col items-center justify-end rounded-lg p-3 text-xs text-[#ffffffa2] sm:w-[400px] lg:h-[180px] lg:w-[195px]"
            style={{ backgroundColor: '#829392' }}
          >
            <span className="w-full p-[4px] text-xl text-white lg:text-[25px]">
              50%
            </span>
            <p className="text-start text-[14px] lg:text-left">
              reduction in time spent on manual financial processes
            </p>
          </div>

          {/* Image Box */}
          <div
            className="mt-10 flex h-[200px] w-[90%] items-end justify-center rounded-lg sm:h-[300px] sm:w-[400px] lg:h-[320px] lg:w-[450px]"
            style={{ backgroundColor: '#DAEEBD' }}
          >
            <img
              className="h-[180px] w-[250px] sm:h-[260px] sm:w-[340px] lg:h-[280px] lg:w-[360px]"
              src="statement.webp"
              alt="Image"
            />
          </div>
        </FadeInWrapper>

        {/* Catalyst */}

        <div className="mt-[350px] flex justify-center p-5 text-center text-[30px]">
          <h1 className="max-w-[550px]">
            A
            <i
              className="text-2xl"
              style={{
                fontFamily: 'Playfair Display, serif',
                fontStyle: 'italic',
              }}
            >
              {' '}
              Catalyst
            </i>{' '}
            for transformation in the world of finance.
          </h1>
        </div>

        {/* problems div 1. */}

        <div className="mt-12 flex flex-col items-center justify-center gap-10 px-4 lg:mt-16 lg:h-[600px] lg:flex-row lg:gap-10">
          {/* Image Section */}
          <FadeInWrapper
            direction="left"
            className="flex h-full max-w-full justify-center"
          >
            <img
              className="h-auto w-[80vw] max-w-[500px] rounded-lg lg:h-[600px] lg:w-[500px]"
              src="storyperson.webp"
              alt="Problem Image"
            />
          </FadeInWrapper>

          {/* Text Section */}
          <FadeInWrapper
            direction="right"
            className="flex w-full max-w-lg flex-col gap-6 text-center lg:gap-5 lg:text-left"
          >
            {/* Problem Header */}
            <div
              className="flex h-[80px] items-center gap-3 border-b-2 px-6"
              id="borderwala"
            >
              <span className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-gray-300 p-4 text-xs lg:text-base">
                01
              </span>
              <span className="text-base lg:text-lg">The Problem</span>
            </div>

            {/* Overburdened Teams */}
            <div className="flex flex-col items-start justify-center rounded-md bg-[#144944] p-5 text-white">
              <span className="text-sm">Overburdened Teams</span>
              <p className="mt-2 text-start text-xs text-[#829392] lg:text-sm">
                The increasing volume of transactions, regulatory compliance,
                and stakeholder demands have left finance teams stretched thin.
              </p>
            </div>

            {/* Time-Consuming Tasks */}
            <div className="flex flex-col items-start justify-center rounded-md bg-[#144944] p-5 text-start text-white">
              <span className="text-sm">Time-Consuming Tasks</span>
              <p className="mt-2 text-xs text-[#829392] lg:text-sm">
                Manual processes, data retrieval, and report generation consume
                valuable time that could be better spent on strategic
                initiatives.
              </p>
            </div>

            {/* Knowledge Gaps */}
            <div className="flex flex-col items-start justify-center rounded-md bg-[#144944] p-5 text-white">
              <span className="text-sm">Knowledge Gaps</span>
              <p className="mt-2 text-start text-xs text-[#829392] lg:text-sm">
                Staying up-to-date with financial regulations, industry trends,
                and company-specific information is a daunting task.
              </p>
            </div>
          </FadeInWrapper>
        </div>

        {/* problems div 2. */}
        <div className="mt-12 flex flex-col items-center justify-center gap-10 px-4 lg:mt-32 lg:h-[600px] lg:flex-row lg:gap-10">
          {/* Text Section */}
          <FadeInWrapper
            direction="left"
            className="flex w-full max-w-lg flex-col gap-6 text-center lg:gap-5 lg:text-left"
          >
            {/* Solution Header */}
            <div
              className="flex h-[80px] items-center gap-3 border-b-2 px-6"
              id="borderwala"
            >
              <span className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-gray-300 p-4 text-sm lg:text-base">
                02
              </span>
              <span className="text-base lg:text-lg">The Solution</span>
            </div>

            {/* Solution Points */}
            <div className="flex flex-col items-start justify-center rounded-md bg-[#144944] p-5 text-white">
              <span className="text-sm">Streamline Processes</span>
              <p className="mt-2 text-start text-xs text-[#829392] lg:text-sm">
                Automate routine tasks, such as report generation and data
                analysis, freeing up time for more strategic work.
              </p>
            </div>

            <div className="flex flex-col items-start justify-center rounded-md bg-[#144944] p-5 text-white">
              <span className="text-sm">Provide Instant Access</span>
              <p className="mt-2 text-start text-xs text-[#829392] lg:text-sm">
                Offer real-time access to up-to-date information, empowering
                teams to make informed decisions quickly.
              </p>
            </div>

            <div className="flex flex-col items-start justify-center rounded-md bg-[#144944] p-5 text-white">
              <span className="text-sm">Ensure Compliance</span>
              <p className="mt-2 text-start text-xs text-[#829392] lg:text-sm">
                Automatically generate reports and responses that adhere to
                regulatory requirements, minimizing risk and ensuring
                compliance.
              </p>
            </div>
          </FadeInWrapper>

          {/* Image Section */}
          <FadeInWrapper
            direction="right"
            className="flex h-full max-w-full justify-center lg:max-w-[600px]"
          >
            <img
              className="h-auto w-[80vw] max-w-[500px] rounded-lg lg:h-[600px]"
              src="storyperson2.webp"
              alt="Solution Image"
            />
          </FadeInWrapper>
        </div>

        <StoryCarousel />

        <div className="mx-2 flex h-auto flex-col items-center justify-center gap-4 p-4 text-3xl sm:mx-4 lg:mx-24 lg:flex-row">
          <h1 className="text-start">
            Your Questions,{' '}
            <i
              className="text-3xl"
              style={{
                fontFamily: 'Playfair Display, serif',
                fontStyle: 'italic',
              }}
            >
              Answered
            </i>
          </h1>
        </div>

        <div className="mx-4 mt-10 flex flex-col items-center justify-center gap-8 sm:mx-8 lg:mx-24 lg:flex-row lg:items-start">
          <FadeInWrapper
            direction="left"
            className="h-fill flex w-full flex-col gap-3"
          >
            {faqData.map((item, index) => (
              <div
                key={index}
                onClick={() => setOpenIndex(openIndex === index ? null : index)} // Toggle between open and close
                className="w-full max-w-[500px] cursor-pointer rounded-lg bg-slate-100 p-4 hover:bg-slate-400 lg:p-3"
                style={{
                  transitionDuration: '0.4s',
                  backgroundColor: `${openIndex === index ? '#F1FFE6' : ''}`,
                }}
              >
                <span className="flex items-center justify-between text-[16px]">
                  {item.question}{' '}
                  {openIndex === index ? (
                    <FaMinus className="text-sm text-green-800" />
                  ) : (
                    <GrAdd className="text-sm text-green-800" />
                  )}
                </span>
                <div
                  style={{
                    display: `${openIndex === index ? 'block' : 'none'}`,
                  }}
                  className="mt-1 text-[13px] text-slate-500"
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </FadeInWrapper>

          <FadeInWrapper direction="right" className="w-full max-w-[500px]">
            <img
              className="m-0 h-full max-w-full rounded-lg object-contain"
              src="storyquestion.webp"
              alt="drone"
            />
          </FadeInWrapper>
        </div>
        <Terms />
        <div className="w-full">
          <Footer />
        </div>
      </div>
    </>
  );
}
